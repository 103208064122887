<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="权限值">
              <a-input
                v-decorator="[
                  'code',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="名称">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="所属模块">
              <a-select
                v-decorator="[
                  'moduleUuid',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in moduleList"
                  :key="item.uuid"
                  :value="item.uuid"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="所属组名">
              <a-input
                v-decorator="[
                  'groupName',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="接口路径">
              <a-input
                v-decorator="[
                  'path',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="描述"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-textarea
                :auto-size="{ minRows: 3 }"
                v-decorator="['remarks']"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary">保存</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { fetchDetail, edit } from "@/api/setting/permission";
import { fetchList as fetchModuleList } from "@/api/setting/module";

export default {
  name: "editPermission",

  data() {
    return {
      form: this.$form.createForm(this),

      uuid: null,

      moduleList: [],
    };
  },

  activated() {
    this.getModuleList();

    const { query } = this.$route;
    const { uuid } = query || {};

    if (uuid && this.uuid !== uuid) {
      this.uuid = uuid;

      fetchDetail({
        uuid,
      }).then((res) => {
        this.form.setFieldsValue({
          code: res.code,
          name: res.name,
          moduleUuid: res.module ? res.module.uuid : undefined,
          groupName: res.groupName,
          path: res.path,
          remarks: res.remarks,
        });
      });
    }
  },

  methods: {
    getModuleList() {
      fetchModuleList({
        pageNum: 1,
        pageSize: 1000,
      }).then((res) => {
        if (Array.isArray(res.list)) {
          this.moduleList = res.list;
        }
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          edit({
            uuid: this.uuid,
            ...values,
          }).then(() => {});
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}
</style>
